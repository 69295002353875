import Logo from '@components/base/logo/logo';
import { NavLink } from 'react-router-dom';
import { footerNav } from '@data';
import BlockSocials from '@components/blocks/socials/socials';
import './footer.scss';

function Footer(): JSX.Element {

  const listItems = footerNav.map(({ title, to, list }) => {
    const sublistItems = list?.map((item) => (
      <li key={item.title}>
        <NavLink to={item.to} className='footer__sublist-item'>{item.title}</NavLink>
      </li>
    ));

    return (
      <li key={title}>
        <NavLink to={to} className='footer__list-item'>{title}</NavLink>
        {
          list && (
            <ul className='footer__sublist'>
              { sublistItems }
            </ul>
          )
        }
      </li>
    );
  });

  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className="footer__content">
          <div className='footer__main'>
            <Logo className='footer__logo'/>
            <BlockSocials />
          </div>
          <nav className='footer__nav'>
            <ul className='footer__list'>
              { listItems }
            </ul>
          </nav>
        </div>
        <div className="footer__bottom">
          <span className="footer__copyright">Copyright © 2024 ATME. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

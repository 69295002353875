import type { AccessData } from '@types';
import { createMarkup } from '@utils/utils';
import './access.scss';

interface Props {
  data: AccessData;
}

function Access(props: Props): JSX.Element {
  const { title, description } = props.data;

  return (
    <section className='access'>
      <div className='access__inner'>
        <h2 className='access__title' dangerouslySetInnerHTML={createMarkup(title)}></h2>
        {
          description && (
            <p className='access__description' dangerouslySetInnerHTML={createMarkup(description)} />
          )
        }
      </div>
      <picture>
        <img
          className='access__image'
          src={'/img/access/access.png'}
          alt={title}
        />
      </picture>
    </section>
  );
}

export default Access;

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import {AdvantagesData} from '@types';
import './advantages.scss';

interface AdvantagesProps {
  data: AdvantagesData;
}

function Advantages(props: AdvantagesProps): JSX.Element {
  const {
    data
  } = props;

  const classes = classNames(
    'advantages',
    {'advantages--secondary': data.secondary}
  );

  const advantagesContent = data.items.map((item) => (
    <SwiperSlide
      tag='li'
      key={item.id}
    >
      <div className='advantages__item'>
        <h3 className='advantages__item-title'>{ item.title }</h3>
        <p className='advantages__item-text' dangerouslySetInnerHTML={{__html: item.text}}></p>
      </div>
    </SwiperSlide>
  ));

  return (
    <section className={classes}>
      <div className='advantages__inner'>
        <h2 className='h2 advantages__title'>{ data.title }</h2>
        {data.text && <div className={'advantages__text'} dangerouslySetInnerHTML={{__html: data.text}}></div>}
        <Swiper
          className='advantages__content'
          wrapperTag='ul'
          wrapperClass='advantages__list'
          slidesPerView={3}
          spaceBetween={42}
          speed={500}
          mousewheel
          breakpoints={{
            320: {
              slidesPerView: 1.1,
              spaceBetween: 24
            },
            641: {
              slidesPerView: 1.5,
              spaceBetween: 42
            },
            1025: {
              slidesPerView: 2.25
            },
            1281: {
              slidesPerView: 3
            }
          }}
        >
          { advantagesContent }
        </Swiper>
      </div>
      <picture>
        {/* <source srcSet={'/img/advantages/advantages-lg.png'}
          type='image/webp'
          media='(min-width: 1024px) and (max-width: 1280px)'
        /> */}
        <img
          className='advantages__image'
          src={data.picture}
          alt=''
        />
      </picture>
    </section>
  );
}

export default Advantages;

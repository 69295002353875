import type {
  HeaderNav,
  HeaderAction,
  PromoContent,
  AccessData,
  AbstractData,
  AdvantagesData,
  FooterSocial,
  FooterNavItem,
  Main,
  DevelopmentMain,
  Contact,
  RaiseCapital,
  MiningCompanies,
  PrivateEquity,
  Commodities
} from './types/types';

const headerNav: HeaderNav[] = [
  { title: 'RAISE CAPITAL', to: '/raise-capital' },
  { title: 'HOW IT WORKS', to: '/development' },
  { title: 'SERVICES', to: '/development' },
  {
    title: 'ABOUT US',
    to: '/about',
    list: [
      /* { title: 'Our story, mission and vision', to: '' },
      { title: 'Board of Directors', to: '' },
      { title: 'Management Board', to: '' }, */
      { title: 'Careers', to: '/careers' },
      { title: 'Contact Us', to: '/contacts' },
    ]
  },
  {
    title: 'RESOURCES',
    to: '/development',
    /* list: [
      { title: 'Our story, mission and vision', to: '/development' },
      { title: 'Board of Directors', to: '/development' },
      { title: 'Management Board', to: '/development' },
      { title: 'Careers', to: '/development' },
      { title: 'Contact Us', to: '/contacts' },
    ] */
  }
];

const headerActions: HeaderAction[] = [
  // { title: 'login', to: '/development', primary: true },
  { title: 'Send request', href: 'mailto:info@atme.com', secondary: true }
];

const main: Main = {
  promo: [
    {
      id: 1,
      majorText: 'New Opportunities for Investors, Innovative Growth for Companies',
      minorText: 'Digital Assets Exchange Licensed by the Central Bank of Bahrain',
      link: {
        href: 'mailto:info@atme.com',
        title: 'Schedule a free consultation'
      }
    }
  ],
  access: {
    title: 'ATME is the digital assets exchange licensed by the Central Bank of Bahrain.',
    description: '<p>We provide access to alternative investments by fractionalizing assets through tokenization. This approach reduces barriers to entry, making these assets more accessible and liquid.</p><p>Investors gain from streamlined buying and selling processes, enabling more efficient management of diversified portfolios and offering greater flexibility for entering and exiting investments.</p>'
  },
  abstract: {
    title: 'Alternative investments available through ATME',
    /* picture: {
      original: '/img/abstract/abstract.png',
      lg: '/img/abstract/abstract-lg.png'
    }, */
    items: [
      {
        id: 1,
        title: 'Private equity',
        text: 'Private equity has long been an attractive investment option for institutional investors and high-net-worth individuals (HNWIs) due to its potential for high returns. Over the period from 2000 to 2023, private equity delivered an annualized return of 11%, significantly outperforming the 6.2% return of public equity benchmarks, such as the S&P 500. By providing access to exclusive investment opportunities, ATME empowers qualified investors to benefit from the high-growth potential of private equity markets, while also providing secondary market liquidity.*',
        source: '* sources: https://www.investopedia.com/ask/answers/040615/how-do-returns-private-equity-investments-compare-returns-other-types-investments.asp https://caia.org/blog/2024/04/23/long-term-private-equity-performance-2000-2023',
        to: '/private-equity'
      },
      {
        id: 2,
        title: 'Private debt',
        text: 'Private debt offers strategic advantages to investors, including higher returns and lower correlation with public market fluctuations. This asset class demonstrates resilience during economic downturns, making it a valuable component of a well-diversified portfolio. Through ATME, qualified investors can gain access to private debt opportunities that provide a hedge against volatility and potential for substantial returns.',
        source: '',
        to: '/private-debt'
      },
      {
        id: 3,
        title: 'Commodities',
        text: 'Commodities remain a critical component of any balanced portfolio due to their lack of correlation with traditional financial assets like stocks and bonds. The copper market alone is projected to surpass $504 billion by 2033, while gold, with a global market capitalization of $15 trillion, continues to serve as a strong hedge against inflation and market instability. ATME offers investors fractionalized access to these robust and stable markets, fostering greater liquidity and portfolio diversification.',
        source: '',
        to: '/commodities'
      },
      {
        id: 4,
        title: 'Real estate',
        text: 'Real estate is a time-tested investment known for its long-term appreciation in value. As a tangible asset, it typically displays a low correlation with traditional asset classes, providing a buffer against market volatility. ATME allows investors to participate in real estate investments, enabling exposure to a sector that consistently offers reliable returns and long-term value growth.',
        source: '',
      },
      {
        id: 5,
        title: 'Art',
        text: 'Art as an asset class holds both emotional and financial value, often demonstrating a low correlation with conventional markets such as stocks and bonds. During periods of market volatility or economic downturns, the value of art may remain stable or even rise, offering a reliable hedge against market risks. Through ATME, investors can access exclusive art investment opportunities, further diversifying their portfolios with this unique and culturally significant asset class.',
        source: '',
      },
      {
        id: 6,
        title: 'And more...',
      }
    ]
  },
  advantages: {
    title: 'ATME is Your first choice partner',
    picture: '/img/advantages/advantages.png',
    items: [
      {
        id: 1,
        title: 'Compliant with regulation',
        text: 'Our operations are fully licensed and regulated by the Central Bank of Bahrain, ensuring that all transactions meet the highest standards of compliance and security. This enhances investment integrity and allows you to focus on capitalizing on opportunities.',
      },
      {
        id: 2,
        title: 'Secure private blockchain technology',
        text: 'ATME leverages Hyperledger Fabric, an enterprise-grade private blockchain framework, to offer unparalleled security, transparency, and compliance in all transactions. This safeguards your investments from external threats and internal vulnerabilities.',
      },
      {
        id: 3,
        title: 'Customised services',
        text: 'Our team of dynamic and passionate industry professionals ensures that you receive dedicated support throughout your investment journey',
      },
    ]
  },
  partners: {
    title: 'Our Partners',
    brands: [
      {
        id: 1,
        name: 'Bahrain Fintech Bay',
        text: 'ATME  partners with Bahrain Fintech Bay - an ecosystem builder - to drive the adoption of tokenized real-world assets (RWAs) in the Kingdom of Bahrain and the wider region.',
        image: '/img/main/BFB.png'
      },
    ]
  },
  news: {
    title: 'Media about Us',
    list: [
      {
        id: 1,
        link: 'https://www.unlock-bc.com/132083/bahrains-atme-pioneersnew-era-middle-eastern-capital-markets-real-world-asset-tokenization/',
        logo: '/img/main/news-logo-2.png',
        image: '/img/main/news-1.png',
        name: 'Bahrain’s ATME Pioneers New Era in Middle Eastern',
        text: 'ATME, a digital assets exchange licensed by the Central Bank of Bahrain (CBB), is setting new',
        date: '05.11.24',
        source: 'UNLOCK'
      },
      {
        id: 2,
        link: 'https://www.khaleejtimes.com/kt-network/atme-set-to-transform-regional-capital-markets-through-real-world-asset-tokenisation',
        logo: '/img/main/news-logo-1.png',
        image: '/img/main/news-2.png',
        name: 'ATME set to transform regional capital markets',
        text: 'ATME, the digital assets exchange licensed by the Central Bank of Bahrain (CBB), is advancing',
        date: '06.11.24',
        source: 'Khaleej Times'
      },
      {
        id: 3,
        link: 'https://menews247.com/digital-assets-exchange-atme-set-to-transform-regional-capital-markets-through-real-world-asset-tokenization/',
        logo: '/img/main/news-logo-3.png',
        image: '/img/main/news-1.png',
        name: 'Digital Assets Exchange ATME Set to Transform',
        text: 'ATME, the digital assets exchange licensed by the Central Bank of Bahrain (CBB), is advancing its',
        date: '05.11.24',
        source: 'Middle East NEWS 247'
      },
      {
        id: 4,
        link: 'https://www.gdnonline.com/Details/1334090',
        logo: '/img/main/news-logo-5.png',
        image: '/img/main/news-5.png',
        name: 'First digital assets exchange launched',
        text: 'ATME tokenizes high-value assets, allowing institutional investors to purchase fractional shares.',
        date: '03.10.24',
        source: 'GDNonline'
      },
      {
        id: 5,
        link: 'https://www.albiladpress.com/news/2024/5833/finance/880484.html',
        logo: '/img/main/news-logo-6.png',
        image: '/img/main/news-6.png',
        name: 'Digital Assets Exchange ATME Launches',
        text: 'ATME tokenizes high-value assets, allowing institutional investors to purchase fractional shares.',
        date: '03.10.24',
        source: 'Albiladpress'
      },
      {
        id: 6,
        link: 'https://www.alayam.com/alayam/economic/1069986/News.html',
        logo: '/img/main/news-logo-7.png',
        image: '/img/main/news-7.png',
        name: 'Launch of ATME Digital Assets Exchange',
        text: 'ATME tokenizes high-value assets, allowing institutional investors to purchase fractional shares.',
        date: '03.10.24',
        source: 'Alayam'
      },
    ]
  }
};

const promo: PromoContent[] = [
  {
    id: 1,
    majorText: 'New Opportunities for Investors, Innovative Growth for Companies',
    minorText: 'Digital Assets Exchange Licensed by the Central Bank of Bahrain',
    link: {
      href: 'mailto:info@atme.com',
      title: 'Schedule a free consultation'
    }
  },
];

const access: AccessData = {
  title: 'ATME is the digital assets exchange licensed by the Central Bank of Bahrain.',
  description: 'We provide access to alternative investments by fractionalizing assets through tokenization. This approach reduces barriers to entry, making these assets more accessible and liquid.<br /><br />Investors gain from streamlined buying and selling processes, enabling more efficient management of diversified portfolios and offering greater flexibility for entering and exiting investments.'
};

const abstract: AbstractData = {
  title: 'Alternative investments available through ATME',
  picture: {
    original: '/img/abstract/abstract.png',
    lg: '/img/abstract/abstract-lg.png'
  },
  items: [
    {
      id: 1,
      title: 'Private equity',
      text: 'Private equity has long been an attractive investment option for institutional investors and high-net-worth individuals (HNWIs) due to its potential for high returns. Over the period from 2000 to 2023, private equity delivered an annualized return of 11%, significantly outperforming the 6.2% return of public equity benchmarks, such as the S&P 500. By providing access to exclusive investment opportunities, ATME empowers qualified investors to benefit from the high-growth potential of private equity markets, while also providing secondary market liquidity.*',
      source: '* sources: https://www.investopedia.com/ask/answers/040615/how-do-returns-private-equity-investments-compare-returns-other-types-investments.asp https://caia.org/blog/2024/04/23/long-term-private-equity-performance-2000-2023',
      to: '/private-equity'
    },
    {
      id: 2,
      title: 'Private debt',
      text: 'Private debt offers strategic advantages to investors, including higher returns and lower correlation with public market fluctuations. This asset class demonstrates resilience during economic downturns, making it a valuable component of a well-diversified portfolio. Through ATME, qualified investors can gain access to private debt opportunities that provide a hedge against volatility and potential for substantial returns.',
      source: '',
      to: '/private-debt'
    },
    {
      id: 3,
      title: 'Commodities',
      text: 'Commodities remain a critical component of any balanced portfolio due to their lack of correlation with traditional financial assets like stocks and bonds. The copper market alone is projected to surpass $504 billion by 2033, while gold, with a global market capitalization of $15 trillion, continues to serve as a strong hedge against inflation and market instability. ATME offers investors fractionalized access to these robust and stable markets, fostering greater liquidity and portfolio diversification.',
      source: '',
      to: '/commodities'
    },
    {
      id: 4,
      title: 'Real estate',
      text: 'Real estate is a time-tested investment known for its long-term appreciation in value. As a tangible asset, it typically displays a low correlation with traditional asset classes, providing a buffer against market volatility. ATME allows investors to participate in real estate investments, enabling exposure to a sector that consistently offers reliable returns and long-term value growth.',
      source: '',
    },
    {
      id: 5,
      title: 'Art',
      text: 'Art as an asset class holds both emotional and financial value, often demonstrating a low correlation with conventional markets such as stocks and bonds. During periods of market volatility or economic downturns, the value of art may remain stable or even rise, offering a reliable hedge against market risks. Through ATME, investors can access exclusive art investment opportunities, further diversifying their portfolios with this unique and culturally significant asset class.',
      source: '',
    },
    {
      id: 6,
      title: 'And more...',
    }
  ]
};

const advantages: AdvantagesData = {
  title: 'ATME is Your first choice partner',
  picture: '/img/advantages/advantages.png',
  items: [
    {
      id: 1,
      title: 'Compliant with regulation',
      text: 'Our operations are fully licensed and regulated by the Central Bank of Bahrain, ensuring that all transactions meet the highest standards of compliance and security. This enhances investment integrity and allows you to focus on capitalizing on opportunities.',
    },
    {
      id: 2,
      title: 'Secure private blockchain technology',
      text: 'ATME leverages Hyperledger Fabric, an enterprise-grade private blockchain framework, to offer unparalleled security, transparency, and compliance in all transactions. This safeguards your investments from external threats and internal vulnerabilities.',
    },
    {
      id: 3,
      title: 'Customised services',
      text: 'Our team of dynamic and passionate industry professionals ensures that you receive dedicated support throughout your investment journey',
    },
  ]
};

const footerSocials: FooterSocial[] = [
  {
    id: 1,
    name: 'instagram',
    href: 'https://www.instagram.com/atmeexchange'
  },
  {
    id: 2,
    name: 'facebook',
    href: 'https://www.facebook.com/atmeexchange'
  },
  {
    id: 3,
    name: 'linkedin',
    href: 'https://www.linkedin.com/company/atmeexchange/'
  },
  {
    id: 4,
    name: 'xcom',
    href: 'https://x.com/atmeexchange'
  },
];

const footerNav: FooterNavItem[] = [
  {
    title: 'raise capital',
    to: '/raise-capital',
  },
  {
    title: 'How it works',
    to: '/development',
  },
  {
    title: 'Services',
    to: '/development',
  },
  {
    title: 'About Us',
    to: '/development',
    list: [
      { title: 'Our story, mission and vision', to: '/development' },
      { title: 'Board of Directors', to: '/development' },
      { title: 'Management Board', to: '/development' },
      { title: 'Careers', to: '/development' },
      { title: 'Contact Us', to: '/development' },
    ]
  },
  {
    title: 'Resources',
    to: '/development',
    list: [
      { title: 'News', to: '/development' },
      { title: 'Insights', to: '/development' },
      { title: 'FAQs', to: '/development' },
    ]
  },
  {
    title: 'Legal',
    to: '/development',
    list: [
      { title: 'Whitepapers', to: '/development' },
      { title: 'General Terms and Conditions', to: '/development' },
      { title: 'Privacy Notice', to: '/development' },
      { title: 'Fees', to: '/development' },
      { title: 'Risk Disclosure', to: '/development' },
      { title: 'Complaints Policy', to: '/development' },
      { title: 'Listing Rules', to: '/development' },
      { title: 'Market Rules', to: '/development' },
    ]
  },
];

const developmentMain: DevelopmentMain = {
  title: 'The page is under development',
  button: {
    to: '/',
    title: 'Go back to the main page'
  }
};

const contactsList: Contact[] = [
  {
    type: 'contact',
    title: 'For complaints',
    item: {
      to: 'mailto:complain@atme.com',
      name: 'email-protection',
      title: 'complain@atme.com'
    }
  },
  {
    type: 'contact',
    title: 'For inquiries, support etc.',
    item: {
      to: 'mailto:info@atme.com',
      name: 'email-info',
      title: 'info@atme.com'
    }
  },
  {
    type: 'socials',
    title: 'ATME in social media'
  },
];

const About = {
  story: {
    head: {
      title: 'About Us',
      description: 'ATME began as a bold idea to redefine the landscape of capital markets through cutting-edge technology and an unwavering commitment to security and transparency. Recognizing the transformative potential of blockchain, we sought to reimagine how real-world assets are traded and managed. With the regulatory oversight of the Central Bank of Bahrain, ATME was established as a pioneering platform that merges principles of traditional finance with the possibilities of digital innovation to offer a secure and compliant exchange for tokenized assets.'
    },
    bottom: [
      {
        title: 'Mission',
        description: 'To transform capital markets by seamlessly connecting businesses seeking capital with investors pursuing high-value alternative assets. Through our trusted and regulated platform, we are committed to empowering all our stakeholders\' growth and diversification objectives'
      },
      {
        title: 'Vision',
        description: 'To be the global standard in financial technology, fostering a secure ecosystem where businesses and investors effortlessly interact to realize their ambitions.'
      }
    ]
  },

  directors: {
    title: 'Board of Directors',
    links: [
      {
        name: 'His Highness Shaikh Khalid bin Mohammed Al Khalifa',
        to: ' '
      },
      {
        name: 'His Highness Shaikh Salman bin Khalid Al Khalifa',
        to: ' '
      },
      {
        name: 'Bashar Al Khaja',
        to: ' '
      },
      {
        name: 'Dr. Haider Al Majali, PhD economics/ corporate law',
        to: ' '
      }
    ]
  },

  staff: {
    title: 'Management board',
    items: [
      {
        id: 1,
        name: 'Alex Lola',
        image: '/img/staff/Alex-Lola.png',
        position: 'Chief Executive Officer',
        previewText: 'With over 15 years of experience in finance, he is a former board member and Head of Retail Banking at Rosbank (part of the Société Générale Group).',
        detailText: 'Alex Lola is the visionary CEO of ATME. Since the company\'s inception in 2023, he has led ATME to establish a state-of-the-art digital asset exchange platform, secure regulatory approval from the Central Bank of Bahrain, and launch with ambitious goals. Under his leadership, ATME is poised to become the leading platform for asset tokenization, drive blockchain adoption, and unlock new investment opportunities in the region<br /><br />With over 15 years of professional experience in the financial sector and multinational corporations, Alex has a proven track record of success. He has held leadership positions at Société Générale Group and its subsidiaries, including a board member role for Société Générale Russia. In this role, he demonstrated his ability to scale a retail business with assets worth $7 billion. His expertise in corporate governance is further evidenced by his tenure on the boards of Société Générale Insurance Russia and United Cards Services.<br /><br />Alex\'s journey into finance and technology began with a strong academic foundation in information security. He earned his Master\'s degree from Carnegie Mellon University, where he honed his skills and developed a deep understanding of the complexities of digital security and blockchain technology. Alex\'s technical acumen, coupled with his forward-thinking leadership, positions him as a key figure in driving ATME\'s mission to redefine trading and investment through FinTech innovation.'
      },
      {
        id: 2,
        name: 'Alaa Al-Shuwaikh',
        image: '/img/staff/Alaa.png',
        position: 'Chief Risk Officer',
        previewText: '20+ years of experience in risk management, corporate governance, control management, business continuity, crisis management, data protection, and privacy.',
        detailText: 'Alaa joined ATME in April 2024, bringing with him over 20 years of expertise in risk management, corporate governance, and crisis management across the Middle East. He has played a critical role in transforming ATME\'s risk management practices, ensuring the company\'s resilience and operational efficiency.<br /><br />Prior to joining ATME, Alaa held various leadership roles where he developed comprehensive risk management frameworks from the ground up, achieving industry certifications for business continuity and resilience. His efforts resulted in significant risk reductions and enhanced operational efficiency, driven by robust business continuity plans and effective data protection measures.<br /><br />Alaa holds an MSc in Risk, Crisis, and Resilience Management from the University of Portsmouth and a Bachelor of Science in Information Technology from the University of Bahrain. He is a Certified Risk Professional (CMIRM) from the Institute of Risk Management, an Associate in Risk Management (ARM) from The Institutes, and holds an Advanced Diploma in Insurance (ACII) from the Chartered Insurance Institute. Additionally, Alaa possesses several project management certifications, including PgMP, PMP, PMI-RMP, PMI-ACP, and is an ISO 22301:2019 Lead Implementer.<br /><br />Alaa\'s strong academic background and proactive approach make him a key asset to ATME.His leadership is crucial in driving the company toward its strategic goals, ensuring long- term success and sustainability.'

      },
      /* {
        id: 3,
        name: 'Ashwaq Shukralla',
        image: '/img/staff/Ashwaq.png',
        position: 'Chief Legal Officer',
        description: 'Ashwaq is the visionary CEO of ATME. Since the company\'s inception in 2023, he has led ATME to establish a state-of-the-art digital asset exchange platform, secure regulatory approval from the Central Bank of Bahrain, and launch with ambitious goals. Under his leadership, ATME is poised to become the leading platform for asset tokenization, drive blockchain adoption, and unlock new investment opportunities in the region<br /><br />With over 15 years of professional experience in the financial sector and multinational corporations, Alex has a proven track record of success. He has held leadership positions at Société Générale Group and its subsidiaries, including a board member role for Société Générale Russia. In this role, he demonstrated his ability to scale a retail business with assets worth $7 billion. His expertise in corporate governance is further evidenced by his tenure on the boards of Société Générale Insurance Russia and United Cards Services.<br /><br />Alex\'s journey into finance and technology began with a strong academic foundation in information security. He earned his Master\'s degree from Carnegie Mellon University, where he honed his skills and developed a deep understanding of the complexities of digital security and blockchain technology. Alex\'s technical acumen, coupled with his forward-thinking leadership, positions him as a key figure in driving ATME\'s mission to redefine trading and investment through FinTech innovation.'

      }, */
      {
        id: 4,
        name: 'Hiba Adnan',
        image: '/img/staff/Hiba-Adnan.png',
        position: 'Chief of Compliance and MLRO',
        previewText: 'Expertise in compliance management gained from 15+ years in financial and technology sectors.',
        detailText: 'Since joining ATME in 2023, Hiba has played a pivotal role in upholding the company\'s commitment to regulatory integrity and reliability.Her leadership has been crucial in ensuring ATME\'s adherence to all relevant regulations, solidifying its reputation for trustworthiness in the industry.<br /><br />With over 15 years of experience in compliance management across the financial and technology sectors, Hiba brings a wealth of knowledge to her role. Her previous positions include Head of Compliance at Amber Technologies, Compliance Officer at Rain, and Deputy MLRO at the Bank of Jordan, where she honed her expertise in navigating complex regulatory environments.<br /><br />Hiba holds a Bachelor\'s degree in Business Administration, an International Diploma in Governance, Risk, and Compliance from the International Compliance Association(“ICA,”) in UK and is certified Anti- Money Laundering Specialist by CAMS in USA.'

      }
    ]
  }
};

const RaiseCapitalData: RaiseCapital = {
  banner: {
    title: 'Open the Gateway to Smarter Capital and Market Expansion',
    text: 'Companies across diverse industries have the opportunity to transform capital raising and market expansion through ATME’s digital assets exchange, licensed by the Central Bank of Bahrain.',
    picture: '/img/raise-capital/promo.jpg'
  },
  next: {
    title: 'Take the next step',
    text: 'Issue tokens up to $5,000,000 with just $5,000',
    description: '<p><b>Disclaimer:</b> <br>' +
      '<b>Offer is valid from 1.10.2024 till 1.05.2025</b><br>' +
      '<b>Offer if valid for new issuers on the platform only\n</b></p>' +
      '<p>$5000 - ATME tokenization fee issuance volume up to $ 5 000 000 </p>' +
      '<p>Issuer covers expenses connected with legal structuring (if necessary), regulatory fees, and  marketing activities (if an issuer opts to have a marketing campaign)<br>' +
      'ATME&apos;s tokenization fee covers  issuance documentation preparation, smart contract development and deployment, issuance procedure on a primary market, and listing of a token for secondary market.</p>',
    button: {
      title: 'Start Your Trial',
      to: 'mailto:info@atme.com'
    }
  },
  why: {
    title: 'Why businesses want tokenisation?',
    picture: '/img/raise-capital/why.jpg',
    items: [
      {
        title: 'To get access to new pools of investors - enhancing the potential for successful fundraising',
        text: 'ATME is simplifying the capital-raising process by leveraging asset tokenization to lower investment thresholds, making it easier for businesses <b>to attract a broader</b> investor base. Through this democratization of investment opportunities, issuers can access previously untapped markets, significantly <b>enhancing the potential for successful fundraising</b> and expanding their reach to a wider pool of investors.'
      },
      {
        title: 'To get access to new ways of raising capital - more cost effective and less time-consuming',
        text: 'By automating key processes and eliminating unnecessary intermediaries, ATME significantly <b>reduces the costs associated with capital raising</b>. Smart contracts further streamline operations, <b>minimizing administrative burdens and human errors</b>. This operational efficiency enables businesses to bring assets to market faster, securing funding more rapidly and accelerating project timelines for growth and innovation.'
      }
    ]
  },
  abstract: {
    title: 'Who can benefit from tokenization ',
    secondary: true,
    items: [
      {
        id: 1,
        title: 'For Mining companies:',
        text: 'With ATME’s innovative technology, mining companies can elevate their ESG credentials, attract a broader base of investors, and meet the increasingly stringent regulatory demands of today&apos;s market.',
        to: '/mining-companies'
      },
      {
        id: 2,
        title: 'For Businesses Looking to Issue Debt:',
        text: 'From legal structuring to KYC compliance and profit distribution, the traditional process of issuing debt can often be slow and costly. ATME streamlines these operations by offering an end-to-end solution, which reduces the time and resources typically required.',
        to: '/businesses'
      },
      {
        id: 3,
        title: 'For the Real Estate developers:',
        text: 'ATME democratizes access to real estate investment, attracting a wider pool of investors and enhancing overall liquidity, all while maintaining the rigorous security and compliance standards essential to the industry.',
        to: '/real-estate'
      },
      {
        id: 4,
        title: 'For Metals producers or sellers:',
        text: 'ATME\'s seamless integration of the digital and physical worlds enables investors to enjoy the benefits of owning physical metals without the hassle of storage or transportation. This boosts liquidity and makes these assets more attractive and accessible, thereby opening up opportunities for producers to reach a wider range of investors.',
        to: '/producers'
      }
    ]
  },
  /* advantages: {
    secondary: true,
    title: 'What sets ATME apart as your preferred partner',
    text: '<p>We don&apos;t just provide technology—we offer a comprehensive suite of services that address every facet of the capital-raising process without adding organizational complexity.</p>',
    picture: '/img/raise-capital/advantages.jpg',
    items: [
      {
        id: 1,
        title: 'End-to-End Tokenization Services',
        text: 'ATME handles every aspect of the tokenization process, from structuring and smart contract development to token issuance and secondary market trading. We facilitate fractional ownership to enhance liquidity and lower investment barriers. Meanwhile, smart contract automation streamlines processes, reduces costs, and mitigates risks.',
      },
      {
        id: 2,
        title: 'Regulatory Compliance',
        text: 'Licensed by the Central Bank of Bahrain (CBB), ATME ensures that all tokenization and trading activities meet stringent regulatory standards. Our integrated KYC and AML processes provide robust compliance, making our platform ideal for sectors with rigorous ESG and regulatory requirements.',
      },
      {
        id: 3,
        title: 'Private Blockchain Technology',
        text: 'Utilizing the enterprise-grade Hyperledger Fabric (HLF) framework, we provide issuers with a secure and permissioned private blockchain that is tailored to their needs. HLF&apos;s architecture ensures that all transactions are transparent, auditable, and compliant with regulatory standards. Its scalability and flexibility allow issuers to manage complex tokenization processes without compromising security.',
      },
    ]
  }, */
  privileges: {
    title: 'What sets ATME apart as your <br> preferred partner',
  },
};

const miningCompaniesData: MiningCompanies = {
  main: {
    title: 'Revolutionizing the Mining Industry',
    text: '<p>The mining industry is at a crossroads. Faced with increasing challenges such as regulatory pressures, environmental concerns, and the need for technological innovation, mining companies are seeking new ways to stay competitive and sustainable. ATME&apos;s advanced digital assets platform offers a groundbreaking solution that can help the mining sector navigate these challenges and thrive in a rapidly evolving market.</p>',
    picture: {
      original: '/img/mining-companies/mining-companies.png',
      lg: '/img/mining-companies/mining-companies-lg.png',
      tablet: '/img/mining-companies/mining-companies-tablet.png'
    }
  },
  info: {
    list: [
      {
        id: 1,
        title: 'Elevating ESG Compliance with Blockchain Transparency',
        icon: 'sustainability',
        description: '<p>Environmental, Social, and Governance (ESG) compliance is no longer optional for mining companies—it&apos;s a business imperative. Stakeholders demand transparency and accountability, and failure to meet these expectations can lead to reputational damage and financial loss.</p><p>ATME&apos;s blockchain-based platform provides an innovative solution by offering seamless and secure tokenization of mining projects. This allows companies to immutably record and share ESG data, from carbon emissions and water usage to community engagement initiatives, ensuring that all stakeholders have access to verified, real-time information. </p><p>By leveraging ATME&apos;s technology, mining companies can enhance their ESG credentials, attract more investors, and meet the stringent regulatory standards of today&apos;s market.</p>'
      },
      {
        id: 2,
        title: 'Streamlining Capital-Raising and Reducing Costs',
        icon: 'market-penetration',
        description: '<p>Raising capital in the mining industry has traditionally been a complex and costly process, often excluding smaller investors due to high entry barriers. ATME is transforming this landscape by enabling fractional ownership of mining assets through tokenization. This innovative approach democratizes investment opportunities, allowing a broader range of investors to participate in mining projects.</p><p>Additionally, ATME&apos;s platform automates key processes such as settlement and profit distribution, reducing the need for intermediaries and lowering operational costs. For mining companies, this means quicker access to capital, reduced financial overheads, and an accelerated path to market.</p>'
      },
      {
        id: 3,
        title: 'Driving Technological Innovation and Operational Efficiency',
        icon: 'industrial-robot',
        description: '<p>In an industry where technological advancement is crucial, ATME&apos;s platform stands out by providing a secure and scalable foundation for digital transformation.</p><p>Built on enterprise-grade Hyperledger Fabric (HLF) technology, ATME enables the tokenization of various mining assets, facilitating easier management and trading. This not only streamlines operations but also enhances productivity and safety through the integration of smart contracts and other advanced digital tools.</p><p>By adopting ATME&apos;s technology, mining companies can stay ahead of the curve, implementing cutting-edge solutions that improve efficiency and reduce risks.</p>'
      },
      {
        id: 4,
        title: 'Catalyzing Industry Transformation',
        icon: 'mining',
        description: '<p>In a sector as challenging as mining, the ability to innovate is key to long-term success.</p><p>ATME&apos;s comprehensive suite of solutions addresses the sector&apos;s most pressing challenges, from ESG compliance to capital raising and technological innovation. By partnering with ATME, mining companies are primed to operate more sustainably, efficiently, and profitably, ensuring they remain competitive.</p>'
      },
    ]
  },
  bannerLink: {
    title: 'If you are looking to stay ahead in a competitive and ever-changing industry, contact us today',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const businessesData: MiningCompanies = {
  main: {
    title: 'Revolutionizing Private Debt Markets',
    text: '<p>In the evolving landscape of private debt markets, where operational efficiency, regulatory compliance, and market accessibility are paramount, ATME offers a transformative platform designed to address the core challenges you face daily.</p><p>Built on an enterprise-grade Hyperledger Fabric (HLF) blockchain, ATME is not just another digital asset platform; it&apos;s a comprehensive solution engineered to enhance transparency, streamline operations, and unlock new investment opportunities.</p>',
    picture: {
      original: '/img/businesses/businesses.png',
      lg: '/img/businesses/businesses-lg.png',
      tablet: '/img/businesses/businesses-tablet.png'
    }
  },
  info: {
    list: [
      {
        id: 1,
        title: 'Streamlining Complex Operations',
        icon: 'ic',
        description: '<p>The private debt market is inherently complex, with numerous operational processes that can be both time-consuming and costly. From legal structuring and KYC compliance to profit distribution, these steps can slow down transactions and inflate costs.</p><p>ATME simplifies these operations by providing an end-to-end service that integrates all essential components under one platform. We manage the structuring, create smart contracts, and oversee investor onboarding, KYC, settlement, and profit distribution. By centralizing these services, ATME reduces the time and resources typically required, allowing your teams to focus on strategic decision-making rather than operational minutiae.</p>'
      },
      {
        id: 2,
        title: 'Enhancing Market Accessibility and Liquidity',
        icon: 'planning',
        description: '<p>One of the significant barriers in the private debt market is the high entry threshold, which limits access to a broader range of investors.</p><p>ATME addresses this through fractionalization and automation, enabling you to offer private debt opportunities to a wider audience without increasing the operational burden. This approach not only democratizes access to private debt investments but also significantly enhances market liquidity, making it easier for investors to enter and exit positions.</p>'
      },
      {
        id: 3,
        title: 'Ensuring Regulatory Compliance and Security',
        icon: 'government',
        description: '<p>In the realm of private debt, compliance and security are paramount. ATME is built on a robust, private blockchain infrastructure that prioritizes these critical aspects.</p><p>We ensure that all participants undergo thorough KYC processes, and our use of a private blockchain mitigates risks associated with counterparty default and fraud.</p>'
      },
      {
        id: 4,
        title: 'Unlocking New Funding Channels and Investment Opportunities',
        icon: 'channels',
        description: '<p>ATME doesn’t just simplify existing processes; it opens up entirely new avenues for funding and investment.</p><p>By leveraging our tokenization capabilities, businesses can offer innovative investment products that appeal to a broader, more diverse investor base. This not only increases your market reach but also enables better asset price discovery, giving you a competitive edge in a crowded market.</p>'
      },
    ]
  },
  bannerLink: {
    title: 'Position your business for sustained growth and leadership. Connect with us today to get started.',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const realEstateData: MiningCompanies = {
  main: {
    title: 'Transforming Real Estate Capital Markets',
    text: '<p>Real estate market today suffers from tight liquidity, high entry barriers for investors, and the complex, time-consuming processes involved in capital raising. ATME is designed to address these specific pain points by seamlessly integrating the power of blockchain technology with comprehensive services.</p>',
    picture: {
      original: '/img/real-estate/real-estate.png',
      lg: '/img/real-estate/real-estate-lg.png',
      tablet: '/img/real-estate/real-estate-tablet.png'
    }
  },
  info: {
    list: [
      {
        id: 1,
        title: 'Unlocking Liquidity and Market Access',
        icon: 'market-access',
        description: '<p>One of the most pressing issues in the real estate market is liquidity. High property prices and the need for significant upfront capital often restrict market participation to large investors, leaving smaller investors out. ATME tackles this challenge by leveraging the power of tokenization and fractionalization.</p><p> Our platform allows you to divide large real estate assets into smaller, more manageable tokens that can be traded on a regulated exchange. This process democratizes investment opportunities, attracting a broader base of investors and increasing overall liquidity without compromising on the security and compliance standards you expect.</p>'
      },
      {
        id: 2,
        title: 'Simplifying and Accelerating Capital Raising',
        icon: 'accelerating',
        description: '<p>The traditional capital-raising process in real estate is riddled with complexities, from legal structuring to investor onboarding and KYC compliance. ATME simplifies this process through our end-to-end service model.</p><p>We handle everything from structuring  and creating smart contracts to managing investor onboarding and ensuring KYC compliance. By removing the need for multiple intermediaries, ATME not only accelerates time-to-market but also reduces the underlying costs.</p>'
      },
      {
        id: 3,
        title: 'Ensuring Transparency and Security',
        icon: 'ensuring-security',
        description: '<p>ATME’s platform is built on enterprise-grade Hyperledger Fabric blockchain technology, which provides a robust framework for secure tokenization. Every transaction is immutably recorded, ensuring that you and your investors have full visibility and trust in the process. Our private blockchain model also prevents assets from being stolen or moved into public networks, offering an added layer of protection against counterparty and fraud risks.</p><p>Moreover, by integrating KYC processes directly into the blockchain, we ensure that all participants on the platform are fully compliant with regulatory standards, thereby reducing your exposure to legal and financial risks.</p>'
      },
      {
        id: 4,
        title: 'Reducing Operational Complexity Through Automation',
        icon: 'automation',
        description: '<p>Managing a large and diverse investor base can be operationally intensive and costly. ATME addresses this by integrating automation into our platform’s core functions.</p><p>Smart contracts automate the execution of agreements and transactions, reducing the need for manual oversight and minimizing the risk of errors. This automation allows you to scale your operations effectively, accommodating more investors without a corresponding increase in operational burdens.</p>'
      },
    ]
  },
  bannerLink: {
    title: 'Position your business for sustained growth and leadership. Connect with us today to get started.',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const producersData: MiningCompanies = {
  main: {
    title: 'Redefining the Commodity Market',
    text: '<p>The precious and base metals markets, with their unique challenges surrounding security, liquidity, and regulatory compliance, stand to benefit greatly from innovative solutions that offer investors access to physical metals without the complexities of custodianship and transportation. ATME is uniquely positioned to address these challenges.</p>',
    picture: {
      original: '/img/producers/producers.png',
      lg: '/img/producers/producers-lg.png',
      tablet: '/img/producers/producers-tablet.png'
    }
  },
  info: {
    list: [
      {
        id: 1,
        title: 'Liquidity through Fractional Ownership',
        icon: 'ownership',
        description: '<p>By fractionalizing metals and other commodities, ATME opens the door to a wider range of investors.</p><p>Our platform enables investors to purchase fractions of high-value physical metals, increasing liquidity and making these assets more attractive and accessible.</p>'
      },
      {
        id: 2,
        title: 'Physical Redemption without the Hassle of Custody and Logistics',
        icon: 'logistics',
        description: '<p>While investors trade digital tokens, they retain the option to redeem them for physical metal at any time. This seamless integration of the digital and physical worlds allows investors to benefit from owning physical metals without the headaches of storage or transportation.</p><p>Custody and logistics are managed by our trusted partners, relieving investors of the burden and lowering barriers to entry, further enhancing liquidity in the metals market.</p>'
      },
      {
        id: 3,
        title: 'Simplifying the Trading Process',
        icon: 'trading',
        description: '<p>We understand the regulatory complexities of the commodities market. ATME simplifies compliance by integrating KYC, structuring, and investor onboarding into the tokenization process. This ensures that all transactions meet regulatory standards while reducing the operational demands on your business.</p>'
      },
      {
        id: 4,
        title: 'Enhanced Security and Regulatory Compliance',
        icon: 'enhanced-security',
        description: '<p>Powered by Hyperledger Fabric, ATME’s private blockchain delivers unmatched security for digital transactions.</p><p>Our platform guarantees that tokenized metals remain within a secure and controlled environment, significantly reducing the risks of fraud and unauthorized access.</p>'
      },
      {
        id: 5,
        title: 'Expanding the Benefits of Tokenization to Other Commodity Markets',
        icon: 'commodity-markets',
        description: '<p>In addition to metals, ATME is equipped to support other commodity markets, including carbon credits, energy certificates, and agricultural products.</p><p>Expand Your Investor Base Today</p>'
      },
    ]
  },
  bannerLink: {
    title: 'Connect with us to explore how ATME can help grow your investor base and streamline your commodity trading operations.',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const privateEquityData: PrivateEquity = {
  analytics: {
    title: 'Redefining Access and <br /> Opportunity in Private Equity',
    description: 'Private equity represents ownership stakes in companies that are not publicly traded, offering a distinct investment opportunity for qualified investors, particularly high-net-worth individuals and institutions. This asset class has long been regarded for its potential to deliver superior returns compared to public markets.',
    info: 'During periods of economic instability, private equity has proven to be resilient, often outperforming public equities when volatility strikes. This stability makes private equity an attractive option for investors looking to diversify their portfolios and achieve competitive, long-term returns despite market uncertainty.',
    schedule: {
      description: 'According to the BMO wealth management*, private equity has outperformed public markets by over 6.96% annually over the past two decades, demonstrating its potential for high yield and consistent growth. While public markets are subject to daily price swings and short-term volatility, private equity investments are generally insulated from such fluctuations, offering a more stable return profile over time.',
      source: '*overview of private equity March, 1 2023',
      image: 'private-schedule'
    }
  },
  opportunities: {
    title: 'The ATME Competitive Edge in these Assets Opportunity',
    description: 'Private equity, while an attractive asset class, has traditionally remained out of reach for many investors due to high entry barriers and the long-term commitment required. ATME leverages the power of private blockchain technology to overcome these challenges, making private equity investments more accessible and flexible for a broader audience.',
    list: [
      {
        image: 'tokenization',
        title: 'Tokenization of Private Equity Assets',
        text: 'Through the tokenization of private equity, ATME dismantles traditional barriers by enabling fractional ownership of investments. This innovative approach lowers the cost of entry, expanding access to private equity for a wider range of investors, including those previously excluded from this high-potential asset class.'
      },
      {
        image: 'liquidity',
        title: 'Enhancing Liquidity',
        text: 'Historically, private equity has lacked liquidity, posing a significant challenge for investors. ATME addresses this by creating a secondary market for tokenized private equity assets, allowing investors to buy and sell their stakes with a click of a button. This enhanced liquidity offers flexibility, providing a solution to one of the key limitations of traditional private equity investments.'
      },
      {
        image: 'ecosystem',
        title: 'Regulated and Transparent Ecosystem',
        text: 'Operating within the regulatory framework of the Central Bank of Bahrain, ATME ensures a secure and compliant environment for all transactions. By leveraging blockchain technology, the platform offers unparalleled transparency, with immutable records of investments that simplify the complex nature of private equity while safeguarding against fraud and mismanagement.'
      },
    ]
  },
  bannerLink: {
    title: 'Ready to discover new frontiers in private equity? <br /> Let&apos;s talk!',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const privateDebtData: PrivateEquity = {
  analytics: {
    secondary: true,
    title: 'Transforming Private Debt Investments',
    description: 'Private debt refers to a form of financing directly negotiated between a lender—often an alternative asset manager—and a borrower, typically a small to mid-sized company. These loans are usually secured by the borrower&apos;s assets and carry floating interest rates, making them a flexible and attractive option for businesses seeking capital.',
    info: 'source <a href="https://www.blackrock.com/institutions/en-zz/insights/growth-drivers">https://www.blackrock.com/institutions/en-zz/insights/growth-drivers</a>',
    infoAdditional: 'With the potential for higher returns, lower correlation to public market volatility, and resilience during economic downturns, private debt offers distinct advantages. These characteristics make private debt a strategic component of any well-diversified portfolio, providing investors with an opportunity to enhance returns while mitigating exposure to broader market fluctuations.',
    schedule: {
      description: 'Private debt has firmly established itself as a significant and scalable asset class, drawing increasing interest from a wide range of investors. As of December 2023, the global private debt market reached over $1.7 trillion, accounting for approximately 13% of the $13.7 trillion alternative investment landscape. This growth is fueled by the rising demand for alternative financing solutions and the appealing risk-return profile of private debt investments.',
      image: 'investments-schedule'
    }
  },
  opportunities: {
    secondary: true,
    title: 'Overcoming Traditional Barriers with ATME',
    description: 'Historically, private debt has been the domain of large institutional investors due to high entry barriers and illiquidity.',
    descriptionAdditional: 'ATME’s platform is changing that by leveraging blockchain technology to tokenize private debt assets, making them more accessible and flexible for a wider range of investors.',
    list: [
      {
        image: 'entry-barriers',
        title: 'Lowering Entry Barriers',
        text: 'Traditional private debt investments often require significant capital commitments and lengthy lock-up periods. ATME democratizes access by enabling fractional ownership, allowing investors to participate with smaller capital outlays. This opens up the market to a broader range of investors, including those who previously found private debt inaccessible.'
      },
      {
        image: 'liquidity-investments',
        title: 'Enhancing Liquidity',
        text: 'One of the primary challenges of private debt has been its illiquidity. ATME addresses this by creating a secondary market for tokenized private debt, providing investors with the flexibility to buy, sell, or trade their holdings before the debt matures. This liquidity solution is a game-changer, enabling dynamic portfolio management and greater financial flexibility.'
      },
      {
        image: 'streamlining-compliance',
        title: 'Streamlining Compliance',
        text: 'Investing in private debt can involve complex regulatory requirements, especially across different jurisdictions. ATME&apos;s private blockchain ensures all transactions are secure and fully compliant with applicable regulations, simplifying the investment process and reducing administrative burdens for investors.'
      },
    ]
  },
  bannerLink: {
    title: 'Ready to diversify your portfolio? <br /> Let&apos;s talk!',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const commoditiesData: Commodities = {
  commodities: {
    title: 'Unlocking New Investment Horizons in Commodities',
    list: [
      {
        id: 1,
        image: 'base-metals',
        title: 'Base Metals: The Backbone of Industrial Growth',
        description: '<p>Base metals such as copper and aluminum are critical to the infrastructure and technological advancements that drive global economies. However, investing in these markets has typically required significant capital and expertise, limited access to a select group of institutional investors.</p>',
        list: [
          {
            id: 1,
            text: '<p><span>Copper</span> is indispensable in industries ranging from construction to renewable energy. The global copper market, already valued at <span>$304 billion</span>, is projected to exceed <span>$504 billion</span> by 2033*, driven by the increasing demand for sustainable technologies such as electric vehicles and renewable energy systems. ATME&apos;s platform tokenizes copper assets, allowing investors to gain exposure to this essential metal without the traditional barriers of high capital requirements and illiquidity.</p>',
            source: '<p>* source <a href=`/`>https://finance.yahoo.com/news/global-copper-market-size-exceed-113000607.html?guccounter=1</a></p>'
          },
          {
            id: 2,
            text: '<p><span>Aluminum</span>, known for its lightweight and corrosion-resistant properties, is crucial to sectors such as aerospace, automotive, and packaging. The global aluminum market continues to expand, fueled by the growing demand for lightweight materials in transport and packaging. Through ATME, investors can participate in this market by acquiring tokenized aluminum assets to invest in one of the most versatile metals in the world.</p>'
          }
        ]
      },
      {
        id: 2,
        image: 'precious-metals',
        title: 'Precious Metals: A Time-Tested Store of Value',
        description: '<p>Precious metals like gold, silver, and platinum have historically been sought for their intrinsic value and ability to hedge against economic uncertainty. However, high entry costs and logistical challenges, such as storage and security, have traditionally restricted access to these assets.</p>',
        list: [
          {
            id: 1,
            text: '<p><span>Gold</span> remains one of the most trusted assets for preserving wealth, with a global market capitalization of <span>$15 trillion*</span>. Its appeal lies in its ability to act as a hedge against inflation and currency fluctuations. ATME&apos;s tokenization platform eliminates the logistical hurdles associated with gold investment by offering digital tokens representing fractional ownership. This innovative approach provides investors with the flexibility to trade their holdings on a regulated secondary market, which enhances their portfolio adaptability.</p>',
            source: '<p>* source <a href=`/`>https://www.voronoiapp.com/markets/Global-Gold-Market-Capitalization-Hits-US-15-Trillion-in-March-2024-809</a></p>'
          },
          {
            id: 2,
            text: '<p><span>Silver</span> and <span>platinum</span> often serve as both industrial metals and stores of value. Silver&apos;s applications in electronics and renewable energy, combined with platinum&apos;s critical role in the automotive and jewelry industries, make them attractive options for diversifying an investment portfolio. ATME provides secure and compliant access to these metals through fractionalization, allowing investors to participate in their growth potential with ease and flexibility.</p>'
          }
        ]
      },
    ]
  },
  advantages: {
    title: 'The ATME Advantage investing into these assets',
    text: '<p>By converting physical assets into digital tokens, we offer investors the opportunity to purchase fractional ownership of valuable assets, eliminating the hassle of transporting and storing physical items. By recording ownership on a secure private blockchain, ATME ensures that investments are not only secure and transparent, but also fully compliant with associated regulatory requirements.</p><p>With ATME, investing in commodities is no longer restricted to the few – it&apos;s an opportunity available to all.</p>',
    picture: '/img/commodities/commodities-advantages.png',
    items: [
      {
        id: 1,
        title: 'Enhanced Liquidity',
        text: 'Tokenized assets provide investors with the ability to buy, sell, or trade their holdings on a regulated secondary market, offering a level of liquidity previously unavailable in these markets.',
      },
      {
        id: 2,
        title: 'Transparency and Security',
        text: 'ATME&apos;s platform is built on a secure private blockchain, ensuring that all transactions are transparent, secure, and compliant with applicable regulations. This technology safeguards your investments and gives you full control and visibility over your assets.',
      },
      {
        id: 3,
        title: 'Regulatory Compliance',
        text: 'Our rigorous adherence to local regulations, including comprehensive KYC procedures, ensures that your investments are fully compliant, reducing risks associated.',
      },
    ]
  },
  bannerLink: {
    title: 'Ready to diversify your portfolio? <br /> Let&apos;s talk!',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const careersData = {
  main: {
    title: 'Careers',
    description: 'Join us in shaping the future of capital markets. Explore opportunities at ATME',
    list: [
      {
        id: 1,
        text: 'We Build Trust with Honesty and Openness'
      },
      {
        id: 2,
        text: 'We Aim High and Keep Growing'
      },
      {
        id: 3,
        text: 'We Own Our Work and Make Smart Decisions'
      },
      {
        id: 4,
        text: 'We Stay Flexible and Ready for Change'
      },
      {
        id: 5,
        text: 'We Focus on Security and Reliability'
      },
    ]
  },
  vacancies: {
    title: 'Join our team:',
    list: [
      {
        id: 1,
        name: 'Backend developer',
        description: 'In this role, you will be responsible for building and maintaining server-side logic, optimizing application performance, and ensuring the seamless integration of various services.',
        responsibilities: [
          {
            id: 1,
            text: 'Develop and maintain the product'
          },
          {
            id: 2,
            text: 'Conduct code reviews'
          },
          {
            id: 3,
            text: 'Assist the QA team in troubleshooting'
          },
        ],
        actions: {
          send: {
            href: 'mailto:recruitment@atme.com',
          },
          more: {
            href: '/files/backend-developer.pdf',
            download: false
          },
        }
      },
      {
        id: 2,
        name: 'Manual QA engineer',
        description: 'The ideal candidate will have a strong background in manual testing, a keen eye for detail, and a passion for ensuring the highest quality of our fintech products.',
        responsibilities: [
          {
            id: 1,
            text: 'Develop and execute test plans'
          },
          {
            id: 2,
            text: 'Perform various tests on platform'
          },
          {
            id: 3,
            text: 'Track bugs using defect systems'
          },
        ],
        actions: {
          send: {
            href: 'mailto:recruitment@atme.com'
          },
          more: {
            href: '/files/qa-engineer.pdf',
            download: false
          },
        }
      },
      {
        id: 3,
        name: 'Marketing specialist',
        description: 'This role will focus on content development for our communication and marketing activities and will involve managing communications (external and internal) and social media efforts.',
        responsibilities: [
          {
            id: 1,
            text: 'Develop compelling content'
          },
          {
            id: 2,
            text: 'Manage ATME&apos;s social media'
          },
          {
            id: 3,
            text: 'Develop internal communication plans'
          },
        ],
        actions: {
          send: {
            href: 'mailto:recruitment@atme.com'
          },
          more: {
            href: '/files/market-specialist.pdf',
            download: false
          },
        }
      },
    ]
  },
  bannerLink: {
    secondary: true,
    title: 'Didn&apos;t find the role you were looking for?',
    text: 'Submit your CV, and we&apos;ll reach out when a suitable opportunity becomes available',
    buttonName: 'Send your CV',
    href: 'mailto:recruitment@atme.com',
  }
};

const servicesData = {
  services: {
    title: 'Our key services',
    img: '/img/services/services.png',
    cards: [
      {
        id: 1,
        icon: 'raise-capital',
        title: 'Raise capital',
        text: '<p>ATME democratizes access to capital markets, offering innovative ways for businesses to raise capital. By introducing new technology, we offer businesses a tool to raise capital from a wider range of investor classes. This unlocks opportunities for companies that previously faced challenges in accessing capital markets, empowering them to secure funding and drive growth.</p><p>For companies already established in capital markets, ATME optimizes the fundraising process, delivering enhanced cost efficiency and a more seamless experience.</p>',
        listName: 'Here is what you can expect from us:',
        list: [
          {
            id: 1,
            text: 'Deal Structuring'
          },
          {
            id: 2,
            text: 'Token Type Selection'
          },
          {
            id: 3,
            text: 'Token Creation and Smart Contract Development'
          },
          {
            id: 4,
            text: 'Token Minting and Issuance'
          },
          {
            id: 5,
            text: 'KYC (Know Your Customer) Procedures and AML (Anti-Money Laundering) framework'
          },
          {
            id: 6,
            text: 'Subscription and Token Distribution',
            content: '<p>We go beyond creating  product descriptions for investors; by leveraging  our vast  network of trusted partners to promote your token offering. In addition, ATME will help issuers with advertising campaigns to boost visibility and attract potential investors</p>'
          },
          {
            id: 7,
            text: 'Secondary Market Trading'
          },
        ]
      },
      {
        id: 2,
        icon: 'secondary-market',
        title: 'Secondary market (trading platform)',
        text: '<p>AWe provide a marketplace enabling the sourcing, trading, tracking, and exchanging ownership of tokens. Built on Hyperledger Fabric developed by IBM, our platform allows for secure private sub- networks to isolate your project on blockchain.This feature allows businesses to isolate their projects on the blockchain, ensuring that sensitive information remains protected while still benefiting from the decentralized nature of the technology.</p><p>Trading on ATME blockchain platform not only enhances efficiency but also delivers full transparency and traceability for every transaction and token movement. This is especially critical in today\'s market, where increasing ESG(Environmental, Social, and Governance) requirements demand that businesses provide clear, auditable records of their operations.By utilizing blockchain technology, companies can easily meet these transparency standards, ensuring that every action is verifiable, contributing to improved accountability, and reinforcing investor confidence.</p>',
      },
      {
        id: 3,
        icon: 'segments',
        title: 'Access to new customer segments',
        text: '<p>By fractionalizing metals and other commodities, ATME expands access to a broader range of investors. Our platform allows investors to purchase fractions of high-value physical metals, increasing liquidity and making these assets more attractive and accessible to a new pool of potential buyers.</p><p>Additionally, our seamless integration of the digital and physical worlds enables investors to enjoy the benefits of owning physical assets, such as metals, without the hassle of storage or transportation. Metal custody and logistics are managed by our trusted partners, removing this burden from investors and lowering barriers to entry. This approach further enhances liquidity in the metals market, making these assets more appealing to a wider audience of investors.</p>',
      },
      {
        id: 4,
        icon: 'investments',
        title: 'Access to alternative investments',
        text: '<p>We provide access to alternative investments by fractionalizing assets through tokenization. This approach reduces barriers to entry, making these assets accessible to a border investor base thereby improving asset\'s liquidity.</p><p>Investors benefit from streamlined trading processes, which enable more efficient management of diversified portfolios and provide greater flexibility for entering and exiting investments.</p>',
      },
      {
        id: 5,
        icon: 'tokenization-related',
        title: 'Tokenization-related advisory services',
        text: '<p>We offer comprehensive, end-to-end support throughout the entire tokenization process, ensuring full compliance and maximizing investment potential. Our services include asset structuring, selecting the appropriate token type, developing smart contracts, token minting, attracting investors through our ecosystem and advertising (where applicable), conducting investor KYC, managing subscriptions, and facilitating trading on the secondary market in a transparent and compliant environment.</p>',
      },
    ]
  }
};

export {
  main,
  promo,
  headerNav,
  headerActions,
  access,
  abstract,
  advantages,
  footerSocials,
  footerNav,
  developmentMain,
  contactsList,
  About,
  RaiseCapitalData,
  miningCompaniesData,
  businessesData,
  realEstateData,
  producersData,
  privateEquityData,
  privateDebtData,
  commoditiesData,
  careersData,
  servicesData
};

import { useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import './partners.scss';
import { Brand } from '@types';

interface Props {
  data: {
    title: string;
    brands: Brand[];
  };
}

function Partners(props: Props): JSX.Element {

  const { title, brands } = props.data;

  const [brandName, setBrandName] = useState<string>(brands[0].name);
  const [brandText, setbrandText] = useState<string>(brands[0].text);
  const [brandImage, setbrandImage] = useState<string>(brands[0].image);
  const [activeBrand, setActiveBrand] = useState<number>(brands[0].id);

  const brandsList = brands.map((brand) => (
    <SwiperSlide
      tag='li'
      key={brand.id}
    >
      <picture className={`partners__item ${activeBrand === brand.id ? 'active' : ''}`} onClick={() => handleClick(brand)}>
        <img src={brand.image} alt={brand.name} />
      </picture>
    </SwiperSlide>
  ));

  function handleClick(data: Brand) {
    setBrandName(data.name);
    setbrandText(data.text);
    setbrandImage(data.image);

    setActiveBrand(data.id);
  }

  return (
    <section className="partners">
      <div className="partners__inner">
        <h2 className="h2 partners__title">{ title }</h2>
        <div className="partners__top">
          <div className="partners__info">
            <h3 className="partners__info-title">{ brandName }</h3>
            <p className="partners__info-text" dangerouslySetInnerHTML={{__html: brandText}}></p>
          </div>
          <picture className="partners__image">
            <img src={brandImage} alt={brandName} />
          </picture>
        </div>
        <Swiper
          className='partners__bottom'
          wrapperTag='ul'
          wrapperClass='partners__list'
          slidesPerView={8}
          spaceBetween={42}
          speed={500}
          mousewheel
          breakpoints={{
            320: {
              slidesPerView: 2.5,
            },
            641: {
              slidesPerView: 4,
            },
            1025: {
              slidesPerView: 5.5
            },
            1281: {
              slidesPerView: 7
            }
          }}
        >
          {brandsList}
        </Swiper>
      </div>
    </section>
  );
}

export default Partners;

import './banner.scss';
import { BannerItem } from '@types';

interface BannerProps {
  data: BannerItem;
}


function Banner(props: BannerProps): JSX.Element {
  const {
    data
  } = props;

  return (
    <section className='banner'>
      <div className='banner__inner'>
        <h1 className='banner__title'>{ data.title }</h1>
        <div className={'banner__text'} dangerouslySetInnerHTML={{ __html: data.text }}/>
      </div>
      <picture>
        {/* <source srcSet={'/img/banner/banner-lg.png'}
          type='image/webp'
          media='(min-width: 1024px) and (max-width: 1280px)'
        /> */}
        <img
          className='banner__image'
          src={data.picture}
          alt=''
          aria-hidden
        />
      </picture>
    </section>
  );
}

export default Banner;

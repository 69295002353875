import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Commodities from '@components/blocks/commodities/commodities';
import Advantages from '@components/blocks/advantages/advantages';
import BannerLink from '@components/blocks/banner-link/banner-link';
import { commoditiesData } from '@data';
import type { BreadcrumbsItem } from '@types';
import './commodities.scss';

function CommoditiesPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Commodities' }];

  const { commodities, advantages, bannerLink } = commoditiesData;

  return (
    <>
      <Header />
      <main className='main'>
        <Breadcrumbs breadcrumbs={breadcrumbsList}/>
        <Commodities data={commodities} />
        <Advantages data={advantages} />
        <BannerLink data={bannerLink} />
      </main>
      <Footer />
    </>
  );
}

export default CommoditiesPage;

import type { OpportunitiesContent } from '@types';
import BaseSvg from '@components/base/svg/svg';

import './services.scss';

interface Props {
  data: {
    title: string;
    img: string;
    cards: {
      id: number;
      icon: string;
      title: string;
      text: string;
      listName?: string;
      list?: {
        id: number;
        text: string;
        content?: string;
      }[];
    }[];
  };
}

function Services({ data }: Props): JSX.Element {

  const { title, img, cards } = data;

  const servicesList = cards.map((item) => (
    <li key={item.id}>
      <div className='service'>
        <div className="service__icon">
          <BaseSvg name={item.icon} />
        </div>
        <h3 className='service__title'>{item.title}</h3>
        <div className='service__content'>
          <div className='service__text' dangerouslySetInnerHTML={{ __html: item.text }}></div>
          {
            item.list &&
            <div className="service__list-wrap">
              {
                item.listName &&
                  <h4 className='service__list-title' dangerouslySetInnerHTML={{ __html: item.listName }}></h4>
              }
              <ul className='service__list'>
                {
                  item.list.map((subitem) => (
                    <li key={subitem.id}>
                      <div className='service__list-item'>
                        <BaseSvg name='checkmark' />
                        <p dangerouslySetInnerHTML={{ __html: subitem.text }}></p>
                        {
                          subitem.content &&
                          <div className={'service-info'}>
                            <span className={'service-info__btn'}>
                              <BaseSvg name={'info'} />
                            </span>
                            <div className={'service-info__content'} dangerouslySetInnerHTML={{ __html: subitem.content }} />
                          </div>
                        }
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          }
        </div>
      </div>
    </li>
  ));

  return (
    <section className='services'>
      <div className='services__bg'></div>
      <div className='services__inner'>
        <div className='services__top'>
          <h2 className='h2 services__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
          <picture className='services__img-wrap'>
            <img src={img} alt={title} />
          </picture>
        </div>
        <ul className='services__list'>
          {servicesList}
        </ul>
      </div>
    </section>
  );
}

export default Services;

import './raise-capital.scss';
import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Abstract from '@components/blocks/abstract/abstract';
import Privileges from '@components/blocks/privileges/privileges';
import Advantages from '@components/blocks/advantages/advantages';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Banner from '@components/blocks/banner/banner';
import Next from '@components/blocks/next/next';
import Why from '@components/blocks/why/why';
import {RaiseCapitalData} from '@data';
import type { BreadcrumbsItem } from '@types';

function RaiseCapitalPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Raise Capital' }];

  return (
    <>
      <Header />
      <main className='main'>
        <Breadcrumbs breadcrumbs={breadcrumbsList}/>
        <Banner data={RaiseCapitalData.banner}/>
        <Next data={RaiseCapitalData.next}/>
        <Why data={RaiseCapitalData.why}/>
        <Abstract data={RaiseCapitalData.abstract} />
        <Privileges data={RaiseCapitalData.privileges} />
        {/* <Advantages data={RaiseCapitalData.advantages} /> */}
      </main>
      <Footer />
    </>
  );
}

export default RaiseCapitalPage;

import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Analytics from '@components/blocks/analytics/analytics';
import BannerLink from '@components/blocks/banner-link/banner-link';
import Opportunities from '@components/blocks/opportunities/opportunities';
import { privateDebtData } from '@data';
import type { BreadcrumbsItem } from '@types';
import './private-debt.scss';

function PrivateDebt(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Private debt' }];

  const { analytics, opportunities, bannerLink } = privateDebtData;

  return (
    <>
      <Header />
      <main className='main'>
        <Breadcrumbs breadcrumbs={breadcrumbsList}/>
        <Analytics data={analytics} />
        <Opportunities data={opportunities} />
        <BannerLink data={bannerLink} />
      </main>
      <Footer />
    </>
  );
}

export default PrivateDebt;

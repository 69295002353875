import { Route, Routes } from 'react-router-dom';
import { AppRoute } from '@utils/const';
import Main from '@pages/main/main';
import DevelopmentPage from '@pages/development/development';
import ContactsPage from '@pages/contacts/contacts';
import AboutPage from '@pages/about/about';
import RaiseCapitalPage from '@pages/raise-capital/raise-capital';
import MiningCompaniesPage from '@pages/mining-companies/mining-companies';
import BusinessesPage from '@pages/businesses/businesses';
import RealEstatePage from '@pages/real-estate/real-estate';
import ProducersPage from '@pages/producers/producers';
import PrivateEquity from '@pages/private-equity/private-equity';
import PrivateDebt from '@pages/private-debt/private-debt';
import CommoditiesPage from '@pages/commodities/commodities';
import CareersPage from '@pages/careers/careers';
import ServicesPage from '@pages/services/services';

function App(): JSX.Element {
  return (
    <Routes>
      <Route
        index
        path={AppRoute.Main}
        element={<Main/>}
      />
      <Route
        path={AppRoute.ContactsPage}
        element={<ContactsPage />}
      />
      <Route
        path={AppRoute.AboutPage}
        element={<AboutPage />}
      />
      <Route
        path={AppRoute.RaiseCapitalPage}
        element={<RaiseCapitalPage />}
      />
      <Route
        path={AppRoute.MiningCompaniesPage}
        element={<MiningCompaniesPage />}
      />
      <Route
        path={AppRoute.BusinessesPage}
        element={<BusinessesPage />}
      />
      <Route
        path={AppRoute.RealEstatePage}
        element={<RealEstatePage />}
      />
      <Route
        path={AppRoute.ProducersPage}
        element={<ProducersPage />}
      />
      <Route
        path={AppRoute.PrivateEquity}
        element={<PrivateEquity />}
      />
      <Route
        path={AppRoute.PrivateDebt}
        element={<PrivateDebt />}
      />
      <Route
        path={AppRoute.CommoditiesPage}
        element={<CommoditiesPage />}
      />
      <Route
        path={AppRoute.CareersPage}
        element={<CareersPage />}
      />
      <Route
        path={AppRoute.ServicesPage}
        element={<ServicesPage />}
      />

      {/* The page for the development status  */}
      <Route
        path={AppRoute.DevelopmentPage}
        element={<DevelopmentPage />}
      />

      {/* The page for the missing page status */}
      <Route
        path={AppRoute.NotFound}
        element={<DevelopmentPage />}
      />


      <Route
        path= 'any'
      >
        <Route
          index
          element={<p>any</p>}
        />
        <Route
          path='a'
          element={<p>a</p>}
        />
      </Route>
    </Routes>
  );
}

export default App;

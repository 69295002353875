import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import { promo } from '@data';
import BaseButton from '@components/base/button/button';
import { PromoContent } from '@types';
import './promo.scss';

interface Props {
  data: PromoContent[];
}

function Promo(props: Props): JSX.Element {

  const { data } = props;

  return (
    <section className='promo'>
      <div className='promo__inner'>
        <h1 className='promo__title visually-hidden'>New Opportunities for Investors with ATME</h1>
        <Swiper
          wrapperTag='ul'
          wrapperClass='promo__list'
          modules={[Pagination]}
          navigation={
            {
              nextEl: '.promo__btn-next',
              prevEl: '.promo__btn-prev',
              disabledClass: 'sl__btn-disabled'
            }
          }
          pagination={
            {
              el: '.promo__pagination-wrapper',
              clickable: true,
              bulletClass: 'promo__pagination-bullet',
              bulletActiveClass: 'promo__pagination-bullet-active'
            }
          }
          slidesPerView={1}
          speed={500}
          mousewheel
        >
          {
            data
              .map(({ id, majorText, minorText, link }) => (
                <SwiperSlide
                  tag='li'
                  className='promo__list-item'
                  key={id}
                >
                  <div className='promo__info'>
                    <span className='promo__major'>{majorText}</span>
                    <span className='promo__minor'>{minorText}</span>
                    {
                      link && (
                        <BaseButton to={link.href} classes='promo__details' outlink>
                          <span>{link.title}</span>
                        </BaseButton>
                      )
                    }
                  </div>
                  {
                    id && (
                      <picture>
                        <source srcSet={'/img/promo/promo-lg.png'}
                          type='image/webp'
                          media='(min-width: 1025px) and (max-width: 1280px)'
                        />
                        <source srcSet={'/img/promo/promo-md.png'}
                          type='image/webp'
                          media='(min-width: 320px) and (max-width: 1024px)'
                        />
                        <img
                          className='promo__image'
                          src={'/img/promo/promo.png'}
                          alt={majorText && `${majorText}`}
                        />
                      </picture>
                    )
                  }
                </SwiperSlide>
              ))
          }
        </Swiper>
        { promo.length > 1 && (<div className='promo__pagination-wrapper'/>)}
      </div>
    </section>
  );
}

export default Promo;
